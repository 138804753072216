import { PrismicLink, PrismicRichText } from "@prismicio/react"
import { graphql, PageProps } from "gatsby"
import { Transition } from "@headlessui/react"
import { GatsbyImage } from "gatsby-plugin-image"
import { withPrismicPreview, useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import { Handler, useDrag } from "@use-gesture/react"
import { primaryInput } from "detect-it"
import { useMedia } from "../lib/useMedia"
import { RenderConditionally } from "../components/renderConditionally"
import { DeclarativeLoadingGatsbyImage } from "../components/declarativeLoadingGatsbyImage"

// simple modulo function because the built-in can't handle negative numbers
const mod = (n: number, m: number) => {
  return ((n % m) + m) % m
}

const ConceptTemplate = (props: PageProps<Queries.ConceptByUidQuery>): ReactElement => {
  const { data: staticData, ...rest } = props,
    { data, isPreview } = useMergePrismicPreviewData(staticData),
    concept = data?.prismicConcept?.data,
    edges = concept?.gallery?.filter(edge => edge?.image?.gatsbyImageData),
    length = edges?.length ?? 0,
    [activeIndex, setActiveIndex] = useState<number | null>(0),
    activeIndexOrZero = activeIndex ?? 0,
    [activeEdge, setActiveEdge] = useState<any>(),
    [activeCaption, setActiveCaption] = useState<any>(),
    captionLengths = edges?.map(edge => edge?.caption?.text?.length ?? 0) ?? [],
    longestCaptionIndex = captionLengths.indexOf(Math.max(...captionLengths)),
    longestCaption = edges?.[longestCaptionIndex]?.caption?.richText,
    isGrid = activeIndex === null,
    [isSSR, setIsSSR] = useState(true),
    isMobile = useMedia([`(max-width: 639px)`], [true], false),
    isDesktop = useMedia([`(min-width: 640px)`], [true], false),
    maintainFocusRef = useRef<boolean>(false)

    useEffect(() => {
      setIsSSR(false)
    }, [])

    const handleClick = () => {
        const newActiveIndex = mod(activeIndex + 1, length)
        setActiveIndex(newActiveIndex)
      },
      handleDrag: Handler<"drag"> = ({ active, movement: [mx], direction: [xDir], velocity: [vx], cancel, tap }) => {
        if (active && vx > 0.2) {
          const newActiveIndex = mod(activeIndex + (xDir > 0 ? -1 : 1), length)
          setActiveIndex(newActiveIndex)
          cancel()
        }    
      },
      bind = useDrag(handleDrag, {
        axis: `x`,
        preventScroll: true,
        preventScrollAxis: `y`,
      })

  // Store caption in state so it doesn’t disappear and cause reflow when switching back to grid
  useEffect(() => {
    if(typeof activeIndex !== `number`) return
    const newActiveEdge = edges?.[activeIndex],
      newActiveCaption = newActiveEdge?.caption?.richText

    if(newActiveEdge !== activeEdge) setActiveEdge(newActiveEdge)
    if(newActiveCaption !== activeCaption) setActiveCaption(newActiveCaption)
  }, [activeIndex])

  useEffect(() => {
    if(maintainFocusRef.current) {
      const activeButtons = Array.from(document.querySelectorAll(`button[data-active="true"]`)),
        visibleActiveButton = activeButtons.find(button => button.offsetParent !== null)

      if(visibleActiveButton) {
        visibleActiveButton.focus()
        maintainFocusRef.current = false
      }
    }
  }, [activeEdge])

  if(!concept) return <></>

  return (
    <div className="min-h-full">

      <header className="hidden sm:grid fixed z-20 left-[20vw] w-[60vw] top-8 -mt-1.5 grid-cols-2 gap-x-20">
        <h2 className="text-22px">Concepts</h2>
        <h1 className="text-22px">{concept?.title}</h1>
      </header>

      {/* Grid controls */}
      <div className="fixed z-20 top-14 sm:top-8 right-8">
        <div className="relative">
          {/* Grid icon */}
          <Transition
            show={!isGrid}
            appear={true}
            as="button"
            className="absolute right-0 p-2 -m-2"
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            onClick={() => setActiveIndex(null)}
          >
            <div className="pointer-events-none">
              <span className="sr-only">Show image grid</span>
              <svg aria-hidden="true" height="15" viewBox="0 0 39 15" width="39" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" stroke="#708c7d" strokeWidth="1.5">
                  <path d="m0 7.392h38.973"/>
                  <g>
                    <path d="m0 0h39v15h-39z" stroke="none"/>
                    <path d="m.75.75h37.5v13.5h-37.5z" fill="none"/>
                  </g>
                  <path d="m19.487.336v14.111"/>
                </g>
              </svg>
            </div>
          </Transition>
          {/* Single icon */}
          <Transition
            show={isGrid}
            appear={true}
            as="button"
            className="absolute right-0 p-2 -m-2"
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            onClick={() => setActiveIndex(0)}
          >
            <div className="pointer-events-none">
              <span className="sr-only">Show single image</span>
              <svg aria-hidden="true" height="15" viewBox="0 0 39 15" width="39" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" stroke="#708c7d" strokeWidth="1.5">
                  <path d="m0 0h39v15h-39z" stroke="none"/>
                  <path d="m.75.75h37.5v13.5h-37.5z" fill="none"/>
                </g>
              </svg>
            </div>
          </Transition>
        </div>
      </div>

      {/* Break out of layout margins */}
      <div className="sm:-mx-[20vw] sm:px-8">

        {/* Regular page template <main> */}
        <main className="mt-48 sm:mt-[20vh] mb-[145px] sm:mb-[129px]">

          {/* Page grid */}
          <div className="grid sm:grid-cols-2 gap-x-20">

            {/* Media grid */}
            <div className="relative">

              {/* Mobile title, individual images, caption, and concept description */}
              <Transition
                show={!isGrid}
                as="div"
                appear={true}
                className="sm:hidden absolute top-0 inset-x-0 w-[calc(100vw-4rem)]"
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {/* Mobile title */}
                <h1 className="sm:hidden text-15px mb-24">Concepts — {concept?.title}</h1>
                {/* Mobile individual images */}
                <button
                   {...(primaryInput === `touch` ? bind() : {})}
                  className="block w-full sm:hidden relative aspect-[369/287]"
                  data-active={true}
                  onClick={handleClick}
                  tabIndex={0}
                  aria-label="Show next image"
                >
                  {edges?.map((edge, i) => {
                    const loadEagerly = [
                        mod(activeIndexOrZero - 1, length),
                        activeIndexOrZero,
                        mod(activeIndexOrZero + 1, length)
                      ].indexOf(i) > -1

                    return (
                      <Transition
                        show={i === activeIndex}
                        as="div"
                        appear={true}
                        className="absolute inset-0 overflow-hidden"
                        enter="transition-opacity duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        key={i}
                        unmount={false}
                      >
                        <RenderConditionally condition={isSSR || isMobile}>
                          <DeclarativeLoadingGatsbyImage
                            alt={edge.image?.alt ?? ``}
                            className="[position:absolute!important] inset-0"
                            image={edge.image.gatsbyImageData}
                            loading={loadEagerly ? `eager` : `lazy`}
                            objectFit="cover"
                            sizes="calc(100vw - 4rem)"
                          />
                        </RenderConditionally>
                      </Transition>
                    )
                  })}
                </button>
                {/* Mobile caption */}
                <div className="relative">
                  <div
                    aria-hidden={true}
                    className="invisible w-full pt-3.5 pb-0"
                  >
                    <PrismicRichText
                      components={{
                        hyperlink: ({ node, children, key }) => (
                          <PrismicLink
                            key={key}
                            field={node.data}
                            className="-m-0.5 p-0.5 underline transition hocus:text-brand/50"
                          >
                            {children}
                          </PrismicLink>
                        ),
                        paragraph: ({ children }) => (
                          <p className="text-xs">{children}</p>
                        ),
                      }}
                      field={longestCaption}
                    />
                  </div>
                  <div className="absolute top-0 inset-x-0 w-full pt-3.5 pb-0 transition-opacity duration-500">
                    <PrismicRichText
                      components={{
                        hyperlink: ({ node, children, key }) => (
                          <PrismicLink
                            key={key}
                            field={node.data}
                            className="-m-0.5 p-0.5 underline transition hocus:text-brand/50"
                          >
                            {children}
                          </PrismicLink>
                        ),
                        paragraph: ({ children }) => (
                          <p className="text-xs">{children}</p>
                        ),
                      }}
                      field={activeCaption}
                    />
                  </div>
                </div>
                {/* Mobile concept description */}
                <div className="w-full max-w-[480px] mt-16 mb-[145px]">
                  <PrismicRichText
                    components={{
                      hyperlink: ({ node, children, key }) => (
                        <PrismicLink
                          key={key}
                          field={node.data}
                          className="-m-0.5 p-0.5 underline transition hocus:text-brand/50"
                        >
                          {children}
                        </PrismicLink>
                      ),
                    }}
                    field={concept?.description?.richText}
                  />
                </div>
              </Transition>

              {/* Desktop individual images */}
              <div className="hidden sm:block absolute h-full w-full">
                {edges?.map((edge, i) => {
                  const imageData = edge.image.gatsbyImageData,
                    aspectRatio = imageData.width / imageData.height,
                    loadEagerly = [
                        activeIndexOrZero,
                        mod(activeIndexOrZero + 1, length)
                      ].indexOf(i) > -1

                  return (
                    <Transition
                      show={i === activeIndex}
                      as="div"
                      appear={true}
                      className="absolute h-full w-full"
                      enter="transition-opacity duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      key={i}
                      unmount={false}
                    >
                      <button
                        className={`block overflow-hidden h-[60vh] ${aspectRatio < 1 ? `ml-[20vw] sm:ml-[calc(20vw-32px)] max-w-[calc(100%-20vw)] sm:max-w-[calc(100%-20vw+32px)]` : `max-w-full`}`}
                        data-active={i === activeIndex}
                        onClick={(e) => {
                          if(e.target.classList.contains(`focus-visible`)) maintainFocusRef.current = true
                          setActiveIndex(mod(activeIndex + 1, edges.length))
                        }}
                        style={{ aspectRatio: `${imageData.width} / ${imageData.height}` }}
                        tabIndex={i === activeIndex ? 0 : undefined}
                      >
                        <RenderConditionally condition={isSSR || isDesktop}>
                          <DeclarativeLoadingGatsbyImage
                            alt={edge.image?.alt ?? ``}
                            className="h-full pointer-events-none"
                            image={imageData}
                            loading={loadEagerly ? `eager` : `lazy`}
                            objectFit="cover"
                            sizes={`min(${aspectRatio} * 60vh,  (100vw - 4rem - 80px) / 2)`}
                          />
                        </RenderConditionally>
                        <span className="sr-only">Zoom selected image</span>
                      </button> 
                    </Transition>
                  )
                })}
              </div>

              {/* Responsive image grid */}
              <Transition
                show={isGrid}
                as="div"
                appear={true}
                className="grid grid-cols-2 gap-10 sm:gap-20 sm:mb-20"
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {edges?.map((edge, i) => (
                  // Grid item
                  <div
                    className="group relative aspect-[250/175]"
                    key={i}
                  >
                    {/* Grid image */}
                    <button
                      className="block h-full w-full"
                      onClick={(e) => {
                        if(e.target.classList.contains(`focus-visible`)) maintainFocusRef.current = true
                        setActiveIndex(i)
                      }}
                      tabIndex={0}
                    >
                      <GatsbyImage
                        alt={edge.image?.alt ?? ``}
                        className="h-full w-full pointer-events-none"
                        image={edge.image.gatsbyImageData}
                        objectFit="cover"
                        sizes="(min-width: 640px) calc(((100vw - 4rem - 80px) / 2 - 80px) / 2), calc((100vw - 4rem - 40px) / 2)"
                      />
                      <span className="sr-only">Zoom</span>
                    </button>
                    {/* Grid caption */}
                    <div className="hidden sm:block absolute top-[100%] w-full p-3.5 pb-0 opacity-0 group-focus-within:opacity-100 mouse:group-hover:opacity-100 transition-opacity duration-500 sm:line-clamp-3">
                      <PrismicRichText
                        components={{
                          hyperlink: ({ node, children, key }) => (
                            <PrismicLink
                              key={key}
                              field={node.data}
                              className="-m-0.5 p-0.5 underline transition hocus:text-brand/50"
                            >
                              {children}
                            </PrismicLink>
                          ),
                          paragraph: ({ children }) => (
                            <p className="text-xs text-center">{children}</p>
                          ),
                        }}
                        field={edge?.caption?.richText}
                      />
                    </div>
                  </div>
                ))}
              </Transition>
            </div>

            {/* Desktop concept description */}
            <div className="hidden sm:block fixed top-[20vh] left-[calc(50%+40px)] w-[min(480px, )] max-w-[min(100%,480px)]">
              <PrismicRichText
                components={{
                  hyperlink: ({ node, children, key }) => (
                    <PrismicLink
                      key={key}
                      field={node.data}
                      className="-m-0.5 p-0.5 underline transition hocus:text-brand/50"
                    >
                      {children}
                    </PrismicLink>
                  ),
                }}
                field={concept?.description?.richText}
              />
            </div>

          </div>
        </main>
      </div>
    </div>
  )
}

const PageTemplateWithPreview = withPrismicPreview(ConceptTemplate)

export default PageTemplateWithPreview

export const PageQuery = graphql`
  query ConceptByUid($uid: String!) {
    prismicConcept(uid: { eq: $uid }) {
      _previewable
      data {
        description {
          richText
        }
        gallery {
          caption {
            richText
            text
          }
          image {
            alt
            gatsbyImageData
          }
        }
        title
      }
    }
  }
`
